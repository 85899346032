import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap"
import React, { useEffect, useState, useRef } from "react"
import { apiRequest } from "../requests"
import { useParams } from "react-router-dom"
import NavBar from "./NavBar"
import { DisplayText, LoadingView } from "../utils/components"
import { EventDetails } from "../components/EventDetails"
import { TicketSelection } from "../components/TicketSelection"
import { isEmpty, updateObjectState } from "../utils/functions"
import { PaymentOptions, PaymentProviders } from "../components/PaymentOptions"
import { constants } from "../constants"
import { Helmet } from "react-helmet"
import VotingPeriodListItem from "./components/Event/VotingPeriodListItem"
import DownloadAppComponent from "../components/DownloadAppComponent"
import { FaInfo, FaSearch } from "react-icons/fa"
import ContestantListItem from "./components/Event/ContestantListItem"

const initData = {
  account_number: "",
  isEmail: true,
  tier_id: null,
  quantity: 1,
  contact: "",
  processor: "",
  currency: "", // currency variable
  attendee_names: [""],
  otp: "",
}

export default function Event() {
  // get search params
  const urlParams = new URLSearchParams(window.location.search)
  const votingPeriodId = urlParams.get("votingPeriodId")
  const contestantId = urlParams.get("contestantId")
  const instaVote = urlParams.get("instaVote") === "true"

  useEffect(() => {
    if (votingPeriodId && contestantId && !instaVote) {
      window.location.href = `/voting/${votingPeriodId}?contestantId=${contestantId}`
      return
    }
    getEvent(instaVote)
  }, [])

  const validateForm = () => {
    let disable =
      (isEmpty(data.account_number) &&
        data.processor === constants.paymentProcessors.africell) ||
      isEmpty(data.tier_id) ||
      isEmpty(data.contact) ||
      (data.attendee_names && data.attendee_names.length === 0) ||
      data.attendee_names.includes("")
    return disable
  }

  const hidPaymentOptions = () => {
    let hide =
      isEmpty(data.tier_id) ||
      isEmpty(data.contact) ||
      (data.attendee_names && data.attendee_names.length === 0) ||
      data.attendee_names.includes("")
    return hide
  }

  const paymentProviderRef = useRef(null)

  const [modal, setModal] = useState({})
  const [selectedTicket, setSelectedTicket] = useState({
    quantity: 0,
    retail_price: 0,
    payment_processors: [],
  })
  const [hasPaid, setHasPaid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(initData)
  const [event, setEvent] = useState({ organizer: {}, tickets: [] })
  const [periodFilterTerm, setPeriodFilterTerm] = useState("")
  const [votingPeriod, setVotingPeriodd] = useState({})
  const [contestant, setContestant] = useState({})

  const { eventCode } = useParams()

  const getEvent = async (isInstaVote) => {
    setIsLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const orderNumber = urlParams.get("orderNumber")
    const successful = urlParams.get("success")

    let endpoint = `api/event/${eventCode}`
    if (orderNumber) {
      endpoint = `api/order/payment/validation?orderNumber=${orderNumber}&eventCode=${eventCode}`
      if (successful) {
        endpoint += `&success=${successful}`
      }
    } else if (isInstaVote) {
      endpoint = `api/event/${eventCode}?votingPeriodId=${votingPeriodId}&votingContestantId=${contestantId}`
    }

    const { success, response } = await apiRequest.get(endpoint)
    let activeTicket = {}
    if (success) {
      const loadedEvent = response.event
      setEvent(loadedEvent)
      activeTicket = loadedEvent.tickets[0]

      if (orderNumber && response.payment_verified) {
        setModal({
          title: response.alert_title || "Payment Successful",
          body: response.message,
        })
        setShowModal(true)
      } else if (orderNumber && response.form_data) {
        const formData = response.form_data
        setData(formData)
        activeTicket = loadedEvent.tickets.filter(
          (ticket) => ticket.id === formData.tier_id
        )[0]
      }
      setSelectedTicket(activeTicket)
      updateObjectState(setData, { tier_id: activeTicket.id, processor: null })
      if (isInstaVote) {
        setVotingPeriodd(response.voting_period)
        setContestant(response.contestant)
      }
    } else {
      setModal({
        title: "Error",
        body: response.message,
        isError: true,
      })
      setShowModal(true)
    }
    setIsLoading(false)
  }

  const submitPayment = async () => {
    setIsLoading(true)
    setShowModal(false)
    const vals = { ...data }
    if (instaVote) {
      vals.is_instavote = true
      vals.period_id = votingPeriod.id
      vals.contestant_id = contestant.id
    }

    const { success, response } = await apiRequest.post(
      `api/event/${event.id}/pay-init`,
      vals
    )

    if (success) {
      if (data.processor === constants.paymentProcessors.africell) {
        setIsLoading(false)
        setHasPaid(true)

        setShowModal(false)
        updateObjectState(setData, {
          payment_transaction_id: response.payment_transaction_id,
        })
        paymentProviderRef.current?.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        })
      } else if (response.finalized) {
        setModal({
          title: "Payment Successful!",
          body: response.message,
        })
        setHasPaid(false)
        setData(initData)
        setShowModal(true)
        setIsLoading(false)
      } else {
        window.location.href = response.payment_url
      }
    } else {
      setModal({
        title: "Error",
        body: response.message,
        isError: true,
      })
      setShowModal(true)
      setIsLoading(false)
    }
  }

  const confirmPayment = async () => {
    setIsLoading(true)
    setShowModal(false)
    const { success, response } = await apiRequest.post(
      `api/event/pay-verify`,
      data
    )

    if (success) {
      setHasPaid(false)
      setModal({
        title: "Success",
        body: response.message,
      })
      setShowModal(true)
      setData(initData)
    } else {
      setModal({
        title: "Error",
        body: response.message,
        isError: true,
      })
      setShowModal(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    paymentProviderRef.current?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    })
  }, [data.processor])

  if (event.disable_web_orders) {
    return (
      <div className={"mt-3 "}>
        <NavBar />
        <Container>
          <DownloadAppComponent
            subtitle={`Tickets for this event can only be purchased via the app`}
          />
        </Container>
      </div>
    )
  } else {
    return (
      <div>
        <NavBar />
        <LoadingView
          isLoading={isLoading}
          view={
            <Container className={"mb-5 pb-5"}>
              {event?.id && (
                <Helmet>
                  <title>{event.name}</title>
                  <meta
                    property="og:title"
                    content={event.name}
                  />
                  <meta
                    property="og:description"
                    content={event.description}
                  />
                  <meta
                    property="og:image"
                    content={event.image}
                  />
                  <meta
                    property="og:url"
                    content={"https://getventix.com/event/" + event.code}
                  />
                  {/* Other Open Graph tags as needed */}
                </Helmet>
              )}
              <Row>
                <Col
                  lg={"6"}
                  className={"mt-4"}
                >
                  {!instaVote && <EventDetails event={event} />}

                  {instaVote && (
                    <Card>
                      <Card.Header className={"py-4 bg-primary text-white"}>
                        <Card.Title as={"h3"}>Instant Voting</Card.Title>
                        <Card.Subtitle className={"mt-3 fw-bold"}>
                          Any vote you purchase will automatically be used to
                          vote for the contestant below in the category listed.
                        </Card.Subtitle>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          <VotingPeriodListItem
                            period={votingPeriod}
                            clickable={false}
                          />
                          <div className={"mt-3"} />
                          <ContestantListItem
                            contestant={contestant}
                            period={votingPeriod}
                            index={0}
                            showFooter={false}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  )}

                  <div className={"mt-3"}>
                    <DownloadAppComponent
                      subtitle={
                        "For a Smoother Ticket Experience, Try Our App!"
                      }
                    />
                  </div>
                  {event.category === "Voting" && !instaVote && (
                    <div className={"mt-3"}>
                      <Accordion className={"px-0"}>
                        <Card className={"px-0"}>
                          <Accordion.Header
                            eventKey="0"
                            className={"primary-accordion-header"}
                          >
                            <h4 className={"text-white"}>Voting Categories</h4>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className={"my-4"}>
                              <InputGroup>
                                <InputGroup.Text>
                                  <FaSearch />
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  placeholder="Search Voting Categories / Periods"
                                  value={periodFilterTerm}
                                  onChange={(e) => {
                                    setPeriodFilterTerm(e.target.value)
                                  }}
                                />
                              </InputGroup>
                            </div>
                            {event.voting_periods.map((period) => {
                              if (
                                !isEmpty(periodFilterTerm) &&
                                !period.name
                                  .toLowerCase()
                                  .includes(periodFilterTerm.toLowerCase())
                              )
                                return <div />

                              return (
                                <div
                                  key={period.id}
                                  className={"mb-3"}
                                >
                                  <VotingPeriodListItem period={period} />
                                </div>
                              )
                            })}
                          </Accordion.Body>
                        </Card>
                      </Accordion>
                    </div>
                  )}
                </Col>
                <Col
                  lg={"6"}
                  className={"mt-4 pb-4"}
                >
                  <TicketSelection
                    event={event}
                    data={data}
                    setData={setData}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                  />

                  {hidPaymentOptions() && (
                    <Alert
                      variant={"warning"}
                      className={"mb-4"}
                    >
                      <FaInfo className={"me-2"} />
                      Please fill out all the fields above to see your payment
                      options
                    </Alert>
                  )}

                  {!hidPaymentOptions() && (
                    <>
                      <PaymentOptions
                        data={data}
                        setData={setData}
                        selectedTicket={selectedTicket}
                        paymentProvidersConfigs={
                          event.payment_processors_configs
                        }
                        onClick={(val) => {
                          updateObjectState(setData, { processor: val })
                        }}
                      />

                      <PaymentProviders
                        paymentProviderRef={paymentProviderRef}
                        data={data}
                        hasPaid={hasPaid}
                        confirmPayment={confirmPayment}
                        setShowModal={setShowModal}
                        setModal={setModal}
                        selectedTicket={selectedTicket}
                        setData={setData}
                        validateForm={validateForm}
                        submitPayment={submitPayment}
                        event={event}
                        setIsLoading={setIsLoading}
                      />
                    </>
                  )}
                </Col>
              </Row>

              <EventAlertModal
                modal={modal}
                setShowModal={setShowModal}
                showModal={showModal}
                submitPayment={submitPayment}
              />
            </Container>
          }
        />
      </div>
    )
  }
}

function EventAlertModal({ modal, showModal, setShowModal, submitPayment }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header
        closeButton
        className={`${modal?.isError ? "bg-danger" : "bg-success"} text-white`}
      >
        <Modal.Title>{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof modal.body === "string" ? (
          <DisplayText text={modal.body} />
        ) : (
          modal.body
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowModal(false)
          }}
        >
          {!modal.interactive ? "Ok" : "No"}
        </Button>
        {modal.interactive && (
          <Button
            variant="primary"
            onClick={() => {
              submitPayment()
            }}
          >
            Yes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
